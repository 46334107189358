'use client';

import { useState } from 'react';
import { motion } from 'framer-motion';
import getScrollAnimation from './utils/getScrollAnimation'; // Adjust the import path as necessary

export default function Banner() {
  const [bannerOpen, setBannerOpen] = useState<boolean>(true);
  const { offscreen, onscreen } = getScrollAnimation();

  return (
    <>
      {bannerOpen && (
        <motion.div
          className="fixed bottom-0 right-0 w-full md:bottom-8 md:right-12 md:w-auto z-50"
          initial="offscreen"
          animate="onscreen"
          variants={{
            offscreen,
            onscreen: onscreen(), // Execute the function if it needs arguments
          }}
        >
          <div className="bg-slate-800 text-slate-50 text-sm p-3 md:rounded shadow-lg flex justify-between">
            <div className='text-slate-500 inline-flex'>
              <a className="font-medium hover:underline text-slate-50 text-special_yellow-200" href="https://raw.githubusercontent.com/mateusclira/platform-mateusclira/main/Resume%20-%20Mateus%20Lira.pdf" rel="noreferrer">Download my CV</a>
              <span className="italic px-1.5 text-white">or</span>
              <a className="font-medium hover:underline text-emerald-400 nav-link scrollto" href="#subscribe" rel="noreferrer">Get in Touch</a>
            </div>
            <button className="text-slate-500 hover:text-slate-400 pl-2 ml-3 border-l border-gray-700" onClick={() => setBannerOpen(false)}>
              <span className="sr-only">Close</span>
              <svg className="w-4 h-4 shrink-0 fill-current" viewBox="0 0 16 16">
                <path d="M12.72 3.293a1 1 0 00-1.415 0L8.012 6.586 4.72 3.293a1 1 0 00-1.414 1.414L6.598 8l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L9.426 8l3.293-3.293a1 1 0 000-1.414z" />
              </svg>
            </button>
          </div>
        </motion.div>
      )}
    </>
  );
}
