import React, { useEffect } from 'react';
import Typed from 'typed.js';
import { FaTwitter, FaYoutube, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';
import heroBg from '../images/hero-bg.jpg';

const Hero = () => {
  useEffect(() => {
    const typed = new Typed('.typed', {
      strings: ['a Site Reliability Engineer', 'a DevOps Enthusiast', 'Azure Certified', 'AWS Certified', 'a Cloud Specialist'],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section
      id="hero"
      className="relative flex flex-col justify-center items-center min-h-screen text-gray-900 bg-blue-200 md:bg-transparent"
      style={{
        backgroundImage: `url(${heroBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right top',
      }}
    >
      <div className="hero-overlay absolute inset-0 bg-blue-200 md:bg-transparent"></div>
      <div className="container relative mx-auto text-center md:text-left px-4 md:px-0" data-aos="zoom-in" data-aos-delay="100">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 text-gray-700 ml-32">Mateus Lira</h1>
        <p className="ml-32 text-2xl md:text-4xl font-bold text-gray-600">I'm <span className="typed text-blue-800" data-typed-items="Site Reliability Engineer, DevOps Enthusiast, Azure Certified, AWS Certified, Data Specialist"></span></p>
        <div className="ml-32 social-links mt-4 flex justify-center md:justify-start space-x-4">
          <a href="https://twitter.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaTwitter className="text-2xl md:text-4xl" />
          </a>
          <a href="https://youtube.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaYoutube className="text-2xl md:text-4xl" />
          </a>
          <a href="https://instagram.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaInstagram className="text-2xl md:text-4xl" />
          </a>
          <a href="https://www.linkedin.com/in/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaLinkedin className="text-2xl md:text-4xl" />
          </a>
          <a href="https://github.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaGithub className="text-2xl md:text-4xl" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
