import React from 'react';
import { HomeIcon, UserIcon, ServerIcon, InboxIcon, StarIcon } from '@heroicons/react/24/outline';

function Header() {
  return (
    <header id="header" className="flex flex-col justify-center">
      <nav id="navbar" className="navbar nav-menu">
        <ul className="flex flex-col space-y-4">
          <li className="relative group">
            <a href="#hero" className="nav-link scrollto active flex items-center space-x-2">
              <HomeIcon className="h-8 w-8 text-white group-hover:block" />
              <span className="hidden group-hover:block text-white">Home</span>
            </a>
          </li>
          <li className="relative group">
            <a href="#about" className="nav-link scrollto flex items-center space-x-2">
              <UserIcon className="h-8 w-8 text-white group-hover:block" />
              <span className="hidden group-hover:block text-white">About</span>
            </a>
          </li>
          <li className="relative group">
            <a href="#features" className="nav-link scrollto flex items-center space-x-2">
              <ServerIcon className="h-8 w-8 text-white group-hover:block" />
              <span className="hidden group-hover:block text-white">Materials</span>
            </a>
          </li>
          <li className="relative group">
            <a href="#testimonials" className="nav-link scrollto flex items-center space-x-2">
              <StarIcon className="h-8 w-8 text-white group-hover:block" />
              <span className="hidden group-hover:block text-white">Testimonials</span>
            </a>
          </li>
          <li className="relative group">
            <a href="#subscribe" className="nav-link scrollto flex items-center space-x-2">
              <InboxIcon className="h-8 w-8 text-white group-hover:block" />
              <span className="hidden group-hover:block text-white">Get in Touch</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
