import React, { useEffect } from 'react';
import PureCounter from '@srexi/purecounterjs';

function Facts() {
  useEffect(() => {
    new PureCounter();
  }, []);

  return (
    <section id="facts" className="facts py-16">
      <div className="container mx-auto px-4" data-aos="fade-up">
        <div className="section-title text-center">
          <h2 className="text-4xl font-bold">More from me</h2>
        </div>
        <div className="text-center mt-8 mb-6">
          <a
            href="https://raw.githubusercontent.com/mateusclira/platform-mateusclira/main/Resume%20-%20Mateus%20Lira.pdf"
            className="download-button px-6 py-3 bg-yellow-600 text-black rounded-md hover:bg-blue-600 hover:text-white transition duration-300"
            download="Resume - Mateus Lira.pdf"
          >
            Download my CV
          </a>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="count-box p-4 bg-gray-100 rounded-lg shadow-md text-center">
            <i className="bi bi-emoji-smile text-4xl mb-4"></i>
            <div className="text-4xl font-bold">
              <span data-purecounter-start="0" data-purecounter-end="5" data-purecounter-duration="1" className="purecounter"></span>
              <span>+</span>
            </div>
            <span className="text-lg">Years Experienced</span>
          </div>
          <div className="count-box p-4 bg-gray-100 rounded-lg shadow-md text-center">
            <i className="bi bi-journal-richtext text-4xl mb-4"></i>
            <div className="text-4xl font-bold">
              <span data-purecounter-start="0" data-purecounter-end="73" data-purecounter-duration="1" className="purecounter"></span>
              <span>+</span>
            </div>
            <span className="text-lg">Projects</span>
          </div>
          <div className="count-box p-4 bg-gray-100 rounded-lg shadow-md text-center">
            <i className="bi bi-headset text-4xl mb-4"></i>
            <div className="text-4xl font-bold">
              <span data-purecounter-start="0" data-purecounter-end="12720" data-purecounter-duration="1" className="purecounter"></span>
              <span>+</span>
            </div>
            <span className="text-lg">Watched minutes</span>
          </div>
          <div className="count-box p-4 bg-gray-100 rounded-lg shadow-md text-center">
            <i className="bi bi-award text-4xl mb-4"></i>
            <div className="text-4xl font-bold">
              <span data-purecounter-start="0" data-purecounter-end="4" data-purecounter-duration="1" className="purecounter"></span>
              <span>+</span>
            </div>
            <span className="text-lg">Certifications</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Facts;
