import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import HeroMobile from './components/HeroMobile';
import About from './components/About';
import AboutMobile from './components/AboutMobile';
import Facts from './components/Facts';
import Features from './components/Features';
import FeaturesMobile from './components/FeaturesMobile';
import Testimonials from './components/Testimonials';
import Subscribe from './components/Subscribe';
import Footer from './components/Footer';
import { useMainFunctionality } from './main';
import Banner from './components/Banner';
import { isMobile } from 'react-device-detect';

function App() {
  useMainFunctionality();
  return (
    <div className="App">
      <Header />
      {isMobile ? <HeroMobile /> : <Hero />}
      <main id="main">
        {isMobile ? <AboutMobile /> : <About />}
        <Facts />
        {isMobile ? <FeaturesMobile /> : <Features />}
        <Testimonials />
        <Subscribe />
      </main>
      <Banner />
      <Footer />
    </div>
  );
}

export default App;
