import { useEffect } from 'react';

export const useMainFunctionality = () => {
  useEffect(() => {
    const select = (el: string, all: boolean = false): any => {
      el = el.trim();
      if (all) {
        return Array.from(document.querySelectorAll(el));
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type: string, el: string, listener: EventListenerOrEventListenerObject, all: boolean = false) => {
      const selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e: Element) => e.addEventListener(type, listener));
        } else {
          (selectEl as Element).addEventListener(type, listener);
        }
      }
    };

    const onscroll = (el: Document, listener: EventListenerOrEventListenerObject) => {
      el.addEventListener('scroll', listener);
    };

    const navbarlinks = select('#navbar .scrollto', true);
    const navbarlinksActive = () => {
      const position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink: any) => {
        if (!navbarlink.hash) return;
        const section = select(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };

    window.addEventListener('load', navbarlinksActive);
    onscroll(document, navbarlinksActive);

    const scrollto = (el: string) => {
      const elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos,
        behavior: 'smooth'
      });
    };

    const backtotop = select('.back-to-top');
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active');
        } else {
          backtotop.classList.remove('active');
        }
      };
      window.addEventListener('load', toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    on('click', '.mobile-nav-toggle', function (this: HTMLElement, e) {
      select('body').classList.toggle('mobile-nav-active');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    });

    on('click', '.scrollto', function (this: HTMLElement, e) {
      if (select((this as HTMLAnchorElement).hash)) {
        e.preventDefault();

        const body = select('body');
        if (body.classList.contains('mobile-nav-active')) {
          body.classList.remove('mobile-nav-active');
          const navbarToggle = select('.mobile-nav-toggle');
          navbarToggle.classList.toggle('bi-list');
          navbarToggle.classList.toggle('bi-x');
        }
        scrollto((this as HTMLAnchorElement).hash);
      }
    }, true);

    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });

    const preloader = select('#preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove();
      });
    }
  }, []);
};
