import React from 'react';
import profileImg from '../images/profile-img.jpg';

function About() {
  return (
    <section id="about" className="about py-16">
      <div className="container mx-auto px-4" data-aos="fade-up">
        <div className="section-title text-center mb-8">
          <h2 className="text-4xl font-bold">About me</h2>
          <div className="max-w-2xl mx-auto">
            <p className="text-lg">I love technology, especially Open-Source and Cloud Native Solutions. I can help you bring security and scalability to your solution, decreasing your stress and increasing your profit.</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start">
          <div className="lg:w-1/3 w-full mb-8 lg:mb-0">
            <img src={profileImg} className="img-fluid rounded shadow-lg" alt="Profile" />
          </div>
          <div className="lg:w-2/3 w-full lg:pl-12">
            <h3 className="text-3xl font-semibold mb-4 text-center ">Site Reliability Engineer & Cloud Specialist.</h3>
            <h4 className="text-2xl font-semibold mb-4 text-center ">Curiosities about me:</h4>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <ul className="space-y-16 text-l mt-12">
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Circadian Cycle:</strong> <span className="ml-2 text-white">I sleep before 22pm</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Color:</strong> <span className="ml-2 text-white">I might like blue</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Kids:</strong> <span className="ml-2 text-white">Still don't, but I will :)</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Married:</strong> <span className="ml-2 text-white">Almost</span></li>
                </ul>
              </div>
              <div className="w-full md:w-1/2 mt-4 md:mt-0">
                <ul className="space-y-16 text-l mt-12">
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Where do I live?</strong> <span className="ml-2 text-white">Sharks City, Brazil (Recife)</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Degree:</strong> <span className="ml-2 text-white">Computer Engineering</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Almost did:</strong> <span className="ml-2 text-white">Electronics Engineering ¯\_(ツ)_/¯</span></li>
                  <li className="flex items-center"><i className="bi bi-chevron-right mr-2 text-[#FFCB0C]"></i> <strong className="text-[#d1d4d6]">Shy:</strong> <span className="ml-2 text-white">I was afraid to talk even with the waiter</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
