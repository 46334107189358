import React, { useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';

function Subscribe() {
  const [state, handleSubmit] = useForm("mayrzwpk");

  useEffect(() => {
    const loadingElement = document.querySelector('.loading');
    const sentMessageElement = document.querySelector('.sent-message');
    const errorMessageElement = document.querySelector('.error-message');
    const submitButton = document.querySelector('.submit-button');

    console.log('Form state:', state);

    if (state.submitting) {
      console.log('Submitting...');
      loadingElement?.classList.add('show');
      sentMessageElement?.classList.remove('show');
      errorMessageElement?.classList.remove('show');
      submitButton?.classList.add('clicked'); // Add animation class
    } else if (state.succeeded) {
      console.log('Success!');
      loadingElement?.classList.remove('show');
      sentMessageElement?.classList.add('show');
      errorMessageElement?.classList.remove('show');
      submitButton?.classList.remove('clicked'); // Remove animation class
    } else if (state.errors && Object.keys(state.errors).length > 0) {
      console.log('Error!');
      loadingElement?.classList.remove('show');
      sentMessageElement?.classList.remove('show');
      errorMessageElement?.classList.add('show');
      submitButton?.classList.remove('clicked'); // Remove animation class
    } else {
      console.log('Idle...');
      loadingElement?.classList.remove('show');
      sentMessageElement?.classList.remove('show');
      errorMessageElement?.classList.remove('show');
      submitButton?.classList.remove('clicked'); // Remove animation class
    }
  }, [state]);

  return (
    <section id="subscribe" className="contact">
      <div className="container mx-auto px-4 lg:px-32" data-aos="fade-up">
        <div className="section-title text-center">
          <h2 className="text-6xl font-bold">Get in touch</h2>
          <h3 className="text-center text-xl mt-2">I'm one click away from you, let's talk.</h3>
        </div>
        <div className="form-container mt-8 text-gray-800">
          <form
            onSubmit={handleSubmit}
            id="subscribe-form"
            action="https://formspree.io/f/mayrzwpk"
            method="post"
            className="php-email-form bg-gray-200 p-8 rounded-lg shadow-lg"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control w-full p-3 rounded-md"
                  id="name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group mt-3 md:mt-0">
                <input
                  type="email"
                  className="form-control w-full p-3 rounded-md"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required
                />
                <ValidationError prefix="Email" field="email" errors={state.errors} />
              </div>
              <div className="form-group col-span-1 md:col-span-2 mt-3">
                <textarea
                  name="message"
                  className="form-control w-full p-2 rounded-md"
                  id="message"
                  placeholder="Your Message"
                  required
                ></textarea>
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </div>
            </div>
            <div className="my-3 text-center">
              <div className="loading">
                <span className="loading-text py-4 px-2 font-bold text-white">Loading...</span>
              </div>
              <div className="sent-message text-green-400 font-bold">
                <span className="sent-text py-3 px-5">I will get back to you!</span>
              </div>
              <div className="error-message">
                <span className="error-text bg-gray-800 py-3 px-5">Oops! There was an error.</span>
              </div>
            </div>
            <div className="text-center mt-6 flex justify-center items-center space-x-4">
              <button
                type="submit"
                className="submit-button px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-yellow-600 hover:text-black-100 transition duration-300"
              >
                Send me a message
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;
