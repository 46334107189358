import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Swiper as SwiperInstance } from 'swiper';

// Import images directly
import testimonial1 from '../images/testimonials/testimonials-1.jpg';
import testimonial2 from '../images/testimonials/testimonials-2.jpg';
import testimonial3 from '../images/testimonials/testimonials-3.jpg';

const testimonials = [
  {
    img: testimonial1,
    name: 'João Filipe Moura',
    title: 'Tech Lead & SRE @Schub',
    quote: 'Mateus is a very hard working guy with a lot of desire and passion for technology. He has no fear facing new challenges and dives into any problem is put in front of him. His attitude is always open. He accepts and reacts well on negative feedback, and also knows how to receive compliments. Last but not least he is a great team player.',
  },
  {
    img: testimonial2,
    name: 'Luciana Acássia',
    title: 'Software Engineer @Accenture',
    quote: 'Mateus is an incredible professional, he has a great ability to absorb knowledge and especially to pass on the acquired knowledge. His willingness to teach and help is one of his greatest strengths. I was lucky to meet him at the beginning of a career in IT, sharing my day-to-day work, and even luckier not losing touch and continue to learn and being inspired by him, every day.',
  },
  {
    img: testimonial3,
    name: 'Anne Aguiar',
    title: 'Tech Delivery @Accenture',
    quote: 'Mateus is a super competent and dedicated professional. He is a quick thinker and has a lot of technical baggage absorbed with a lot of training and learning practice. We\'ve worked on the same team and I only have positive points to reinforce about his performance and behavior.',
  },
];

function Testimonials() {
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current;

      swiperInstance.on('slideChange', () => {
        swiperInstance.pagination.render();
        swiperInstance.pagination.update();
      });
    }
  }, []);

  return (
    <section id="testimonials" className="testimonials py-16 text-white section-bg">
      <div className="container px-4 mx-auto" data-aos="fade-up">
        <div className="section-title text-center mb-8">
          <h2 className="text-4xl font-bold">Testimonials</h2>
        </div>
        <Swiper
          onSwiper={(swiper: SwiperInstance) => {
            swiperRef.current = swiper;
          }}
          modules={[Pagination, Autoplay]}
          loop={true}
          pagination={{ clickable: true, dynamicBullets: false }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          className="testimonials-slider"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="testimonial-item text-center mx-auto max-w-4xl">
                <img src={testimonial.img} className="testimonial-img mx-auto mb-4 rounded-full" alt={testimonial.name} />
                <h3 className="text-xl font-semibold text-special_yellow-300">{testimonial.name}</h3>
                <h4 className="text-md font-medium mb-4">{testimonial.title}</h4>
                <div className="relative p-8">
                  <p className="italic relative z-10">
                    <span className="text-5xl text-special_yellow-300 absolute -left-8 -top-6">&#10077;</span>
                    {testimonial.quote}
                    <span className="text-5xl text-special_yellow-300 absolute ">&#10078;</span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;
