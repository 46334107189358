import React from 'react';

import feature1 from '../images/azuretf.jpg';
import feature2 from '../images/medium.jpg';
import feature3 from '../images/k8s.jpg';

function FeaturesMobile() {
  return (
    <section id="features" className="features py-16">
      <div className="container mx-auto px-4" data-aos="fade-up">
        <div className="section-title text-center mb-8">
          <h2 className="text-3xl font-bold text-white">Free Materials</h2>
          <p className="text-base text-gray-300 mt-4">Here you can see everything that I created for you. If you want to know more about a specific service, click on each service.</p>
        </div>
        <div className="flex flex-col space-y-8">
          <a href="https://www.youtube.com/watch?v=ceTViUzd4E0&list=PLZdPQj1lFEWinVqZ8tLaPoXOKGCLR2Rhv" target="_blank" rel="noreferrer" className="icon-box block group relative overflow-hidden rounded-lg shadow-md transition-transform transform hover:scale-105 hover:bg-special_yellow-300" data-aos="zoom-in" data-aos-delay="100">
            <div className="absolute inset-0 bg-cover bg-center opacity-40 group-hover:opacity-10 transition-opacity" style={{ backgroundImage: `url(${feature1})` }}></div>
            <div className="absolute inset-0 bg-black opacity-70 group-hover:opacity-0 transition-opacity"></div>
            <div className="relative p-8 text-white group-hover:text-black transition-colors">
              <div className="icon mx-auto mb-4">
                <i className="bx bx-file text-4xl group-hover:text-black"></i>
              </div>
              <h4 className="text-2xl font-semibold">Terraform with Azure</h4>
              <p className="mt-2 mb-4">Learn how to create your infrastructure from the ground zero using Terraform</p>
            </div>
          </a>
          <a href="https://medium.com/@mateusclira" target="_blank" rel="noreferrer" className="icon-box block group relative overflow-hidden rounded-lg shadow-md transition-transform transform hover:scale-105 hover:bg-special_yellow-300" data-aos="zoom-in" data-aos-delay="200">
            <div className="absolute inset-0 bg-cover bg-center opacity-40 group-hover:opacity-10 transition-opacity" style={{ backgroundImage: `url(${feature2})` }}></div>
            <div className="absolute inset-0 bg-black opacity-70 group-hover:opacity-0 transition-opacity"></div>
            <div className="relative p-8 text-white group-hover:text-black transition-colors">
              <div className="icon mx-auto mb-4">
                <i className="bx bx-file text-4xl group-hover:text-black"></i>
              </div>
              <h4 className="text-2xl font-semibold">Articles on Medium</h4>
              <p className="mt-2 mb-4">Read my articles and learn from Technology to Lifestyle</p>
            </div>
          </a>
          <a href="https://www.youtube.com/playlist?list=PLZdPQj1lFEWg_br7pljCYVQlMlb8OAnPF" target="_blank" rel="noreferrer" className="icon-box block group relative overflow-hidden rounded-lg shadow-md transition-transform transform hover:scale-105 hover:bg-special_yellow-300" data-aos="zoom-in" data-aos-delay="300">
            <div className="absolute inset-0 bg-cover bg-center opacity-40 group-hover:opacity-10 transition-opacity" style={{ backgroundImage: `url(${feature3})` }}></div>
            <div className="absolute inset-0 bg-black opacity-70 group-hover:opacity-0 transition-opacity"></div>
            <div className="relative p-8 text-white group-hover:text-black transition-colors">
              <div className="icon mx-auto mb-4">
                <i className="bx bx-tachometer text-4xl group-hover:text-black"></i>
              </div>
              <h4 className="text-2xl font-semibold">Kubernetes Course</h4>
              <p className="mt-2 mb-4">Learn how to create a Kubernetes cluster in this Course for free</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default FeaturesMobile;
