import React from 'react';
import { FaTwitter, FaYoutube, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';

function Footer() {
  return (
    <footer id="footer" className="bg-gray-200 py-2">
      <div className="container mx-auto lg:px-32 justify-center">
        <h3 className="text-4xl font-bold text-gray-800 mb-2 text-center justify-center">Lira Tech</h3>
        <p className="text-blue-800 text-xl mb-4 text-center justify-center">Connect with me</p>
        <div className="social-links flex justify-center space-x-4 mb-6">
          <a href="https://twitter.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaTwitter className="text-4xl" />
          </a>
          <a href="https://youtube.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaYoutube className="text-4xl" />
          </a>
          <a href="https://instagram.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaInstagram className="text-4xl" />
          </a>
          <a href="https://www.linkedin.com/in/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaLinkedin className="text-4xl" />
          </a>
          <a href="https://github.com/mateusclira" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-blue-900">
            <FaGithub className="text-4xl" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
