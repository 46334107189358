type AnimationState = {
    offscreen: {
      y: number;
      opacity: number;
    };
    onscreen: (props?: { duration?: number }) => {
      y: number;
      opacity: number;
      transition: {
        type: string;
        duration: number;
      };
    };
  };
  
  export default function getScrollAnimation(): AnimationState {
    return {
      offscreen: {
        y: 150,
        opacity: 0,
      },
      onscreen: ({ duration = 2 } = {}): {
        y: number;
        opacity: number;
        transition: { type: string; duration: number };
      } => ({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration,
        },
      }),
    };
  }
  